"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Whychoose = () => {
  return (
    <div>
      <section className="bg-white py-6 md:py-16 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-10 lg:mb-20">
            <h2 className="font-extrabold">Why choose UJS Investment</h2>
            <p>
              Choosing UJS Investments means partnering with a leader in
              diversified financial services. Our commitment is to offer:
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
            <div className="space-y-4 lg:space-y-8">
              <div>
                <h5 className="font-bold mb-1">
                  Expert Guidance and Proven Track Record
                </h5>
                <p>
                  Our team of seasoned investment professionals provides
                  insights and strategies tailored to your financial goals.
                </p>
              </div>
              <div>
                <h5 className="font-bold mb-1">Diverse Portfolio Options</h5>
                <p>
                  From startups to real estate and equities, we offer a wide
                  range of investment vehicles to suit various risk appetites.
                </p>
              </div>
              <div>
                <h5 className="font-bold mb-1">Personalized Service</h5>
                <p>
                  Every investor with UJS receives personalized attention and
                  services customized to their individual needs and aspirations.
                </p>
              </div>
              <div>
                <h5 className="font-bold mb-1">Transparency and Integrity</h5>
                <p>
                  We operate with the highest standards of transparency and
                  integrity, ensuring you always have clear information and
                  realistic expectations.
                </p>
              </div>
            </div>
            <div>
              <img
                src="../images/why_choose.png"
                className="object-cover rounded-xl lg:rounded-3xl"
                alt="Startups Track Record"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whychoose;
