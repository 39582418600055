import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/ujsinvestments.com/next.js/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/ujsinvestments.com/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/ujsinvestments.com/next.js/src/app/Goalsolution.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/ujsinvestments.com/next.js/src/app/Intro.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/ujsinvestments.com/next.js/src/app/Simplegrip.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/ujsinvestments.com/next.js/src/app/Whychoose.jsx");
