"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Simplegrip = () => {
  return (
    <div>
      <section className="bg-white py-6 md:py-16 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-10 lg:mb-20">
            <h2 className="font-extrabold">
              Getting Started with UJS Investments
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between ">
            <div>
              <img
                src="../images/simple_grip.png"
                className=" object-cover m-auto"
                alt="Lead Investor"
              />
            </div>
            <div>
              <h5 className="font-semibold">Schedule a Consultation</h5>
              <p>
                Book a free initial consultation to discuss your financial goals
                and explore suitable investment opportunities with our experts.
              </p>
              <h5 className="mt-8 font-semibold">
                Create Your Investment Plan
              </h5>
              <p>
                Tailor a personalized investment strategy that aligns with your
                risk tolerance and financial objectives.
              </p>
              <h5 className="mt-8 font-semibold">
                Fund Your Account and Invest
              </h5>
              <p>
                Deposit funds into your secure UJS account and start your
                investment journey with our guided support.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Simplegrip;
