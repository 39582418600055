"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Intro = () => {
  return (
    <div>
      <section className="bg-lime-50 bg-opacity-5 py-6 md:py-16 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-10 lg:mb-20">
            <h2 className="font-extrabold">
              Criteria for Evaluating Startup Opportunities
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 lg:gap-10 items-center justify-between text-center">
            <div>
              <img
                src="../images/ico_lead_investor.png"
                className=" object-cover m-auto"
                alt="Lead Investor"
              />
              <h4 className="font-bold mt-4">Innovative Edge</h4>
              <p>
                Seek startups with unique, scalable innovations that disrupt
                existing markets and offer sustainable growth.
              </p>
            </div>
            <div>
              <img
                src="../images/ico_track_record.png"
                className="object-cover m-auto"
                alt="Startups Track Record"
              />
              <h4 className="font-bold mt-4">Strong Founding Team</h4>
              <p>
                Prioritize companies led by experienced, committed teams with a
                clear vision and proven management skills.
              </p>
            </div>
            <div>
              <img
                src="../images/ico_industry.png"
                className="object-cover m-auto"
                alt="Industry"
              />
              <h4 className="font-bold mt-4">Market Potential</h4>
              <p>
                Evaluate the size, demand, and competitive landscape of the
                market to ensure significant growth potential.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
